import { graph } from '@/api/graph';
import { showNotification } from '@/dialogs';
import { useDebounceFn, useWindowFocus } from '@vueuse/core';
import { watch } from 'vue';

let throttledCheckVersion = undefined;
let outdatedNotification = undefined;

export const VERSION = {
  commitRef: import.meta.env.VITE_COMMIT_REF || 'development',
  commitBranch: import.meta.env.VITE_COMMIT_BRANCH || 'development',
  commitDate: import.meta.env.VITE_COMMIT_DATE || 'development',
};


export function setupVersionCheck() {
  if (throttledCheckVersion) return;

  throttledCheckVersion = useDebounceFn(checkVersion, 10000, {
    maxWait: 5 * 60 * 1000,
  });


  watch(
    useWindowFocus(),
    (n) => {
      if (n) {
        throttledCheckVersion();
      }
    },
    { immediate: true },
  );
}

export async function checkVersion() {
  if (outdatedNotification) {
    outdatedNotification.close();
  }
  const data = await graph.query('{ version { commitRef commitBranch commitDate } }')();
  if (data.version.commitRef !== VERSION.commitRef || data.version.commitDate !== VERSION.commitDate || data.version.commitBranch !== VERSION.commitBranch) {
    console.log('version outdated!');
    outdatedNotification = showNotification({
      message: 'There are pending application updates. <a href="javascript:location.reload()">Click to reload</a>',
      variant: 'warning',
      position: 'bottom-left',
      indefinite: true,
      closable: true,
    });
    outdatedNotification.then(() => outdatedNotification = undefined);
  } else {
    console.info('Version current!');
  }
}
