import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  actorNoteBasic: `on ActorNote {
    id
    actorId
    ...noteBasic
  }`,
  actorNoteFull: `on ActorNote {
    id
    actorId
    ...noteFull
  }`,
});

const createMutation = graph.mutate(`($input: CreateActorNoteInput!) {
  createActorNote(input: $input) {
    actorNote { ...actorNoteBasic }
    updatedActorTagIds
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateActorNoteInput!) {
  updateActorNote(input: $input) {
    actorNote { ...actorNoteBasic }
    updatedActorTagIds
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteActorNoteInput!) {
  deleteActorNote(input: $input) { actorNoteId }
}`);

// endregion

export function create({ actorNote }) {
  return createMutation({ input: actorNote })
    .catch(wrapGraphQLError)
    .then(result => result.createActorNote);
}

export function update({ actorNoteId, updates }) {
  return updateMutation({ input: { actorNoteId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateActorNote);
}

function _delete({ actorNoteId }) {
  return deleteMutation({ input: { actorNoteId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteActorNote);
}

export { _delete as delete };
