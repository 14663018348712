import { nextTick } from 'vue';

export function checkForm(form) {
  if (!form) throw new Error('Unexpected error reload the page');

  const found = findChildren(form, /^(OSelect|OInput|OAutocomplete|OInputitems|HeightInput)$/);
  return found.filter(input => input.useHtml5Validation && !input.checkHtml5Validity());
}

export function findChildren(parent, matcher) {
  const found = [];
  const root = (parent.subTree) ? parent.subTree : parent.$.subTree;
  if (matcher) {
    walk(root, child => {
      if (matcher.test(child.$options.name)) {
        found.push(child);
        return false;
      }
    });
  } else {
    walk(root, child => found.push(child));
  }
  return found;
}

function walk(vnode, cb) {
  if (!vnode) return;

  if (vnode.component) {
    const proxy = vnode.component.proxy;
    if (proxy) {
      if (cb(vnode.component.proxy) === false) {
        return;
      }
    }
    walk(vnode.component.subTree, cb);
  } else if ((vnode.shapeFlag & 16) > 0) {
    const vnodes = vnode.children;
    for (let i = 0; i < vnodes.length; i++) {
      walk(vnodes[i], cb);
    }
  }
}

export function setFieldValidity(field, variant = null, message = null) {
  return nextTick(() => {
    if (field) {
      // Set type only if not defined
      if (!field.variant) {
        field.newVariant = variant;
      }
      // Set message only if not defined
      if (!field.message) {
        field.newMessage = message;
      }
    }
  });
}

export function propsWithQuery(queryFilter) {
  return (route) => {
    const filtered = queryFilter ?
      queryFilter.reduce((a, b) => ({ ...a, [b]: route.query[b] }), {}) :
      route.query;
    return { ...route.params, ...filtered };
  };
}
