import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  actorHeadshotBasic: `on ActorHeadshot {
    id
    actorId
    name
    ...actorHeadshotUrls
    createdAt
    updatedAt
  }`, actorHeadshotUrls: `on ActorHeadshot {
    urls { style url expiration fileName }
  }`,
});

const listQuery = graph.query(`($actorId: ActorID, $limit: Int, $after: String) {
  actorHeadshots(first: $limit, after: $after, actorId: $actorId) {
    nodes { ...actorHeadshotBasic }
    pageInfo { ...pageInfo }
  }
}`);

const retrieveQuery = graph.query(`($actorHeadshotId: ActorHeadshotID!) {
  actorHeadshot(actorHeadshotId: $actorHeadshotId) {
    ...actorHeadshotBasic
  }
}`);

const retrieveUrlQuery = graph.query(`($actorHeadshotId: ActorHeadshotID!) {
  actorHeadshot(actorHeadshotId: $actorHeadshotId) {
    id
    ...actorHeadshotUrls
  }
}`);

const createMutation = graph.mutate(`($input: CreateActorHeadshotInput!) {
  createActorHeadshot(input: $input) {
    actorHeadshot { ...actorHeadshotBasic }
    primaryActorHeadshotId
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateActorHeadshotInput!) {
  updateActorHeadshot(input: $input) {
    actorHeadshot { ...actorHeadshotBasic }
  }
}`);

const uploadMutation = graph.mutate(`($input: UploadActorHeadshotInput!) {
  uploadActorHeadshot(input: $input) {
    actorHeadshot {
     id
     ...actorHeadshotUrls
    }
    presignedPost { ...presignedPost }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteActorHeadshotInput!) {
  deleteActorHeadshot(input: $input) {
   actorHeadshotId
   primaryActorHeadshotId
  }
}`);

// endregion

export function list({ actorId = null, limit = 10, after = null } = {}) {
  return listQuery({ limit, after, actorId })
    .catch(wrapGraphQLError);
}

export function retrieve({ actorHeadshotId } = {}) {
  return retrieveQuery({ actorHeadshotId })
    .catch(wrapGraphQLError)
    .then((data) => data.actorHeadshot);
}

export function retrieveUrls({ actorHeadshotId } = {}) {
  return retrieveUrlQuery({ actorHeadshotId })
    .catch(wrapGraphQLError)
    .then((data) => data.actorHeadshot);
}

export function create({ actorHeadshot }) {
  return createMutation({ input: actorHeadshot })
    .catch(wrapGraphQLError)
    .then(result => result.createActorHeadshot);
}

export function update({ actorHeadshotId, updates }) {
  return updateMutation({ input: { actorHeadshotId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateActorHeadshot);
}

export function upload({ actorHeadshotId, ...options }) {
  return uploadMutation({ input: { actorHeadshotId, ...options } })
    .catch(wrapGraphQLError)
    .then(result => result.uploadActorHeadshot);
}

function _delete({ actorHeadshotId }) {
  return deleteMutation({ input: { actorHeadshotId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteActorHeadshot);
}

export { _delete as delete };
