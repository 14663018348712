import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers.js';

export const useActorVideoStore = defineStore('actorVideos', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (actorVideoId) => state.records.find((v) => v.id === actorVideoId),
    isLoading: (state) => state.loading > 0,
    forActorId: (state) => (actorId) => state.records.filter((v) => v.actorId === actorId),
  },
  actions: {
    async processRecord(record) {
      return addUpdateRecord(this.records, record);
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(actorVideoId) {
      removeRecord(this.records, actorVideoId);
    },
    async removeRecordsBy({ actorId }) {
      if (actorId) {
        const toRemove = this.forActorId(actorId).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    async retrieveAsync(actorVideoId, force = false) {
      let record = this.records.find((v) => v.id === actorVideoId);
      if (!record || force) {
        await doLoading(this, async () => {
          record = await api.actorVideos.retrieve({ actorVideoId });
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async reorder(actorVideoId, position, relatedActorVideoId = null) {
      if (isExisting(actorVideoId)) {
        const payload = {
          actorVideoId,
          position,
        };
        if (relatedActorVideoId) {
          payload.relatedActorVideoId = relatedActorVideoId;
        }
        return await doLoading(this, async () => {
          return await api.actorVideos.reorder(payload).then(async (data) => {
            await this.processRecords(data.updatedActorVideos);
            return this.processRecord(data.actorVideo);
          });
        });
      } else {
        throw new Error('Actor video ID does not exists');
      }
    },
    async save(actorVideo) {
      if (isExisting(actorVideo.id)) {
        // existing
        const original = this.retrieve(actorVideo.id);
        const payload = {
          actorVideoId: actorVideo.id,
          updates: buildChanges(original, actorVideo, { skip: ['id', 'updatedAt'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.actorVideos.update(payload).then(async (data) => {
              return this.processRecord(data.actorVideo);
            });
          } else {
            return original;
          }
        });
      } else {
        // new actorVideo
        const prepared = {
          actorVideo: clearEmpty(actorVideo, { skip: ['urls'] }),
        };
        return await doLoading(this, async () => {
          return await api.actorVideos.create(prepared).then(async (data) => {
            return this.processRecord(data.actorVideo);
          });
        });
      }
    },
    async delete(actorVideoId) {
      const actorVideo = this.retrieve(actorVideoId);
      if (!actorVideo) {
        return;
      }
      const payload = { actorVideoId };

      return await doLoading(this, async () => {
        return await api.actorVideos.delete(payload).then(async data => {
          await this.removeRecord(data.actorVideoId);
          return data.actorVideoId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActorVideoStore, import.meta.hot));
}
