import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  tagCategoryBasic: `on TagCategory {
    id
    category
  }`,
  tagBasic: `on Tag {
    id
    tag
    options {
      colorName
    }
    tagCategoryId
    category
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String, $category: String, $categoryId: TagCategoryID) {
  tags(first: $limit, after: $after, tagCategoryId: $categoryId, category: $category) {
    nodes { ...tagBasic }
    pageInfo { ...pageInfo }
  }
}`);

const listCategoriesQuery = graph.query(`($limit: Int, $after: String) {
  tagCategories(first: $limit, after: $after) {
    nodes { ...tagCategoryBasic }
    pageInfo { ...pageInfo }
  }
}`);

const createMutation = graph.mutate(`($input: CreateTagInput!) {
  createTag(input: $input) {
    tag { ...tagBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateTagInput!) {
  updateTag(input: $input) {
    tag { ...tagBasic }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteTagInput!) {
  deleteTag(input: $input) { tagId }
}`);

// endregion

export function list({ limit = 10, after = null, category = null, categoryId = null } = {}) {
  return listQuery({ limit, after, category, categoryId })
    .catch(wrapGraphQLError);
}

export function listCategories({ limit = 10, after = null } = {}) {
  return listCategoriesQuery({ limit, after })
    .catch(wrapGraphQLError);
}

export function create({ tag }) {
  return createMutation({ input: tag })
    .catch(wrapGraphQLError)
    .then(result => result.createTag);
}

export function update({ tagId, updates }) {
  return updateMutation({ input: { tagId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateTag);
}

function _delete({ tagId }) {
  return deleteMutation({ input: { tagId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteTag);
}

export { _delete as delete };
