import graphql from 'graphql.js';
import { useAuthStore } from '@/stores/auth';

export const graph = graphql('/graphql', {
  method: 'POST',
  asJSON: true,
  headers: {
    Authorization: () => {
      const store = useAuthStore();
      if (store.valid) {
        const token = store.token;
        return `Bearer ${token}`;
      } else {
        return null;
      }
    },
  },
  debug: import.meta.env.DEV && !import.meta.env.VITEST,
});

export function paramsWithRole(params) {
  if (useAuthStore().type === 'admin') {
    params.isAdmin = true;
  }
  return params;
}

graph.fragment({
  pageInfo: `on PageInfo {
    endCursor
    hasNextPage
  }`,
  presignedPost: `on PresignedPost {
    url fields dynamicFields completionToken expiration
  }`,
  filterSetActor: `on FilterSetActor {
    status
    roleType
    isLocal
    unionAffiliation
    heightComparator
    height
    skillIds
    tagIds
    excludeTagIds
  }`,
  filterSetShow: `on FilterSetShow {
    status
  }`,
  noteBasic: `on Note {
    noteTopicId
    note
    enteredAt
    enteredById
    visibility
  }`,
  noteFull: `on Note {
    ...noteBasic
    noteTopic {
      ...noteTopicBasic,
    }
    enteredBy {
      ...userBasic
    }
  }`,
});
