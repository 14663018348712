import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import { useNoteTopicStore } from '@/stores/note_topics.js';
import { useUserStore } from '@/stores/users.js';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers.js';
import { useActorStore } from "@/stores/actors.js";

export const useActorNoteStore = defineStore('actorNotes', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (actorNoteId) => state.records.find((v) => v.id === actorNoteId),
    isLoading: (state) => state.loading > 0,
    forActorId: (state) => (actorId) => state.records.filter((v) => v.actorId === actorId),
  },
  actions: {
    async processRecord(record) {
      if (record.noteTopic) {
        await useNoteTopicStore().processRecord(record.noteTopic);
      }
      if (record.enteredBy) {
        await useUserStore().processRecord(record.enteredBy);
      }
      return addUpdateRecord(this.records, record, {
        skip: ['noteTopic', 'enteredBy'],
      });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(actorNoteId) {
      removeRecord(this.records, actorNoteId);
    },
    async removeRecordsBy({ actorId }) {
      if (actorId) {
        const toRemove = this.forActorId(actorId).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    // async retrieveAsync(actorNoteId, force = false) {
    //   let record = this.records.find((v) => v.id === actorNoteId);
    //   if (!record || force) {
    //     await doLoading(this, async () => {
    //       record = await api.actorNotes.retrieve({ actorId: actorNoteId });
    //       if (record) {
    //         record = await this.processRecord(record);
    //       }
    //     });
    //   }
    //   return record;
    // },
    async save(actorNote) {
      if (isExisting(actorNote.id)) {
        // existing
        const original = this.retrieve(actorNote.id);
        const payload = {
          actorNoteId: actorNote.id,
          updates: buildChanges(original, actorNote, {
            skip: ['id', 'updatedAt', 'enteredById', 'enteredAt'],
          }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.actorNotes.update(payload).then(async (data) => {
              if (data.updatedActorTagIds) {
                await useActorStore().processRecord({
                  id: data.actorNote.actorId,
                  tagIds: data.updatedActorTagIds,
                });
              }
              return this.processRecord(data.actorNote);
            });
          } else {
            return original;
          }
        });
      } else {
        // new showRole
        const prepared = {
          actorNote: clearEmpty(actorNote, { skip: ['enteredById'] }),
        };
        return await doLoading(this, async () => {
          return await api.actorNotes.create(prepared).then(async (data) => {
            if (data.updatedActorTagIds) {
              await useActorStore().processRecord({
                actorId: data.actorNote.actorId,
                tagIds: data.updatedActorTagIds,
              });
            }
            return this.processRecord(data.actorNote);
          });
        });
      }
    },
    async delete(actorNoteId) {
      const actorNote = this.retrieve(actorNoteId);
      if (!actorNote) {
        return;
      }
      const payload = { actorNoteId };

      return await doLoading(this, async () => {
        return await api.actorNotes.delete(payload).then(async data => {
          await this.removeRecord(data.actorNoteId);
          return data.actorNoteId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActorNoteStore, import.meta.hot));
}
