import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  showRoleBasic: `on ShowRole {
    id
    showId
    actorId
    roleName
    details
    contractDetails
    filters {
      ...filterSetActor
    }
    status
    position
    createdAt
    updatedAt
  }`,
  showRoleForActor: `on ShowRole {
    id
    showId
    actorId
    roleName
    status
    show {
      ...showForActor
    }
    createdAt
    updatedAt
  }`,
  showRoleFull: `on ShowRole {
    ...showRoleBasic
    prospects {
      ...showRoleProspectBasic
    }
  }`,
});

const retrieveQuery = graph.query(`($showRoleId: ShowRoleID!) {
  showRole(showRoleId: $showRoleId) {
    ...showRoleFull
  }
}`);

const createMutation = graph.mutate(`($input: CreateShowRoleInput!) {
  createShowRole(input: $input) {
    showRole { ...showRoleBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateShowRoleInput!) {
  updateShowRole(input: $input) {
    showRole { ...showRoleBasic }
    showRoleProspect { ...showRoleProspectBasic }
  }
}`);

const reorderMutation = graph.mutate(`($input: ReorderShowRoleInput!) {
  reorderShowRole(input: $input) {
    showRole { id position }
    updatedShowRoles { id position }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteShowRoleInput!) {
  deleteShowRole(input: $input) { showRoleId }
}`);

// endregion
export function retrieve({ showRoleId } = {}) {
  return retrieveQuery({ showRoleId })
    .catch(wrapGraphQLError)
    .then((data) => data.showRole);
}

export function create({ showRole }) {
  return createMutation({ input: showRole })
    .catch(wrapGraphQLError)
    .then(result => result.createShowRole);
}

export function update({ showRoleId, updates, options = {} }) {
  return updateMutation({ input: { showRoleId, ...updates, ...options } })
    .catch(wrapGraphQLError)
    .then(result => result.updateShowRole);
}

export function reorder({ showRoleId, position, relatedShowRoleId = null }) {
  return reorderMutation({ input: { showRoleId, position, relatedShowRoleId } })
    .catch(wrapGraphQLError)
    .then(result => result.reorderShowRole);
}

function _delete({ showRoleId }) {
  return deleteMutation({ input: { showRoleId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteShowRole);
}

export { _delete as delete };
