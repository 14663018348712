import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  noteTopicBasic: `on NoteTopic {
    id
    topic
  }`,
  noteTopicAdmin: `on NoteTopic {
    autoTagIds
    autoTags {
      ...tagBasic
    }
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String, $adminUI: Boolean) {
  noteTopics(first: $limit, after: $after) {
    nodes {
      ...noteTopicBasic
      ...noteTopicAdmin @include(if: $adminUI)
    }
    pageInfo { ...pageInfo }
  }
}`);

const createMutation = graph.mutate(`($input: CreateNoteTopicInput!) {
  createNoteTopic(input: $input) {
    noteTopic {
      ...noteTopicBasic
      ...noteTopicAdmin
    }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateNoteTopicInput!) {
  updateNoteTopic(input: $input) {
    noteTopic {
      ...noteTopicBasic
      ...noteTopicAdmin
    }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteNoteTopicInput!) {
  deleteNoteTopic(input: $input) { noteTopicId }
}`);

// endregion

export function list({ limit = 10, after = null, adminUI = false } = {}) {
  return listQuery({ limit, after, adminUI })
    .catch(wrapGraphQLError);
}

export function create({ noteTopic }) {
  return createMutation({ input: noteTopic })
    .catch(wrapGraphQLError)
    .then(result => result.createNoteTopic);
}

export function update({ noteTopicId, updates }) {
  return updateMutation({ input: { noteTopicId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateNoteTopic);
}

function _delete({ noteTopicId }) {
  return deleteMutation({ input: { noteTopicId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteNoteTopic);
}

export { _delete as delete };
