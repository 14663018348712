import Oruga from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@oruga-ui/theme-bulma';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import '@oruga-ui/theme-bulma/dist/bulma.css';

const customBulmaConfig = {
  ...bulmaConfig,
  iconPack: 'fas',
  iconComponent: 'vue-fontawesome',
};

const Theme = {
  install(app, _options = {}) {
    app
      .component('VueFontawesome', FontAwesomeIcon)
      .use(Oruga, customBulmaConfig);
  },
};

export default Theme;
