import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  prefabFilterBasic: `on PrefabFilter {
    id
    name
    model
    position
    filters {
      ...filterSetActor
      ...filterSetShow
    }
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String) {
  prefabFilters(first: $limit, after: $after) {
    nodes { ...prefabFilterBasic }
    pageInfo { ...pageInfo }
  }
}`);

const createMutation = graph.mutate(`($input: CreatePrefabFilterInput!) {
  createPrefabFilter(input: $input) {
    prefabFilter { ...prefabFilterBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdatePrefabFilterInput!) {
  updatePrefabFilter(input: $input) {
    prefabFilter { ...prefabFilterBasic }
  }
}`);

const reorderMutation = graph.mutate(`($input: ReorderPrefabFilterInput!) {
  reorderPrefabFilter(input: $input) {
    prefabFilter { id position }
    updatedPrefabFilters { id position }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeletePrefabFilterInput!) {
  deletePrefabFilter(input: $input) { prefabFilterId }
}`);

// endregion

export function list({ limit = 10, after = null } = {}) {
  return listQuery({ limit, after })
    .catch(wrapGraphQLError);
}

export function create({ prefabFilter }) {
  return createMutation({ input: prefabFilter })
    .catch(wrapGraphQLError)
    .then(result => result.createPrefabFilter);
}

export function update({ prefabFilterId, updates }) {
  return updateMutation({ input: { prefabFilterId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updatePrefabFilter);
}

export function reorder({ prefabFilterId, position, relatedPrefabFilterId = null }) {
  return reorderMutation({ input: { prefabFilterId, position, relatedPrefabFilterId } })
    .catch(wrapGraphQLError)
    .then(result => result.reorderPrefabFilter);
}

function _delete({ prefabFilterId }) {
  return deleteMutation({ input: { prefabFilterId } })
    .catch(wrapGraphQLError)
    .then(result => result.deletePrefabFilter);
}

export { _delete as delete };
