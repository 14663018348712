function basicRequest({ method = 'POST', url, body = null, headers = {} } = {}) {
  const xhr = new XMLHttpRequest();
  xhr.open(method, url, true);
  if (body) {
    xhr.setRequestHeader('Content-Type', 'application/json');
  }
  xhr.setRequestHeader('Accept', 'application/json');
  for (const key in headers) {
    xhr.setRequestHeader(key, headers[key]);
  }
  const promiseResolvers = {};
  const promise = new Promise((p1, p2) => {
    promiseResolvers.resolve = p1;
    promiseResolvers.reject = p2;
  });
  xhr.onerror = function(error) {
    promiseResolvers.reject({ error, xhr });
  };
  xhr.onload = function() {
    try {
      promiseResolvers.resolve(xhr);
    } catch (e) {
      promiseResolvers.resolve(xhr);
    }
  };
  xhr.send(body);
  return promise;
}

export function authenticateWizard({ email, token = null, verify_email = null, password = null } = {}) {
  const params = { email };
  if (token) params.token = token;
  if (verify_email) params.verify_email = verify_email;
  if (password) params.password = password;

  return basicRequest({
    url: '/auth/wizard',
    body: JSON.stringify(params),
  }).then((xhr) => {
    if (xhr.status === 200) {
      return JSON.parse(xhr.responseText);
    } else if (xhr.status === 401) {
      return Promise.reject(JSON.parse(xhr.responseText));
    } else {
      return Promise.reject({ error: 'Failure' });
    }
  });
}

export function authenticate({ username, password } = {}) {
  return basicRequest({
    url: '/auth',
    body: JSON.stringify({ username, password }),
  }).then((xhr) => {
    if (xhr.status === 200) {
      return JSON.parse(xhr.responseText);
    } else if (xhr.status === 401) {
      return Promise.reject(JSON.parse(xhr.responseText));
    } else {
      return Promise.reject({ error: 'Failure' });
    }
  });
}

export function validate({ token } = {}) {
  return basicRequest({
    url: '/auth/validate',
    headers: { Authorization: `Bearer ${token}` },
    body: '',
  }).then((xhr) => {
    if (xhr.status === 200) {
      return JSON.parse(xhr.responseText);
    } else if (xhr.status === 401) {
      return Promise.reject(JSON.parse(xhr.responseText));
    } else {
      return Promise.reject({ error: 'Failure' });
    }
  });
}
