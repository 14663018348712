<script>
export default {
  name: 'ONavbarBurger',
  inheritAttrs: false,
  props: {
    isOpened: Boolean,
  },
};
</script>

<template lang="pug">
a.navbar-burger(
  tabindex="0"
  v-bind="$attrs"
  :aria-expanded="isOpened"
  aria-label="menu"
  :class="{ 'is-active': isOpened}"
  role="button"
)
  span(aria-hidden="true")
  span(aria-hidden="true")
  span(aria-hidden="true")
</template>

<style scoped>

</style>
