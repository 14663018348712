export class UploadManager {
  // @param presignedPost [object] The presigned post data
  // @param onComplete [function] A function to complete the upload
  constructor(presignedPost, onComplete) {
    this.presignedPost = presignedPost;
    this.onComplete = onComplete;
  }

  // Returns the max upload size if set
  //
  // @return null | number
  get maxUploadSize() {
    const content_length_range = this.presignedPost.fields['content-length-range'];

    const maxLength = content_length_range?.split(',')[1];
    if (maxLength === undefined) {
      return null;
    } else {
      return parseInt(maxLength);
    }
  }

  // Aborts the upload
  abort() {
    // TODO
  }

  // Finishes the upload
  finish() {
    this.onComplete(this.presignedPost.completionToken);
  }
}
