import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import { useShowRoleProspectStore } from '@/stores/show_role_prospects';
import { useNoteTopicStore } from '@/stores/note_topics.js';
import { useUserStore } from '@/stores/users.js';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers.js';

export const useShowRoleProspectNoteStore = defineStore('showRoleProspectNotes', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (showRoleProspectNoteId) => state.records.find((v) => v.id === showRoleProspectNoteId),
    isLoading: (state) => state.loading > 0,
    forShowRoleProspectId: (state) => (showRoleProspectId) => state.records.filter((v) => v.showRoleProspectId === showRoleProspectId),
    forShowRoleId: (state) => (showRoleId) => {
      const showRoleProsepectIds = new Set(useShowRoleProspectStore().forShowRoleId(showRoleId).map(e => e.id));
      return state.records.filter((v) => showRoleProsepectIds.has(v.showRoleProspectId));
    },
  },
  actions: {
    async processRecord(record) {
      if (record.noteTopic) {
        await useNoteTopicStore().processRecord(record.noteTopic);
      }
      if (record.enteredBy) {
        await useUserStore().processRecord(record.enteredBy);
      }
      return addUpdateRecord(this.records, record, {
        skip: ['noteTopic', 'enteredBy'],
      });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(showRoleProspectNoteId) {
      removeRecord(this.records, showRoleProspectNoteId);
    },
    async removeRecordsBy({ showRoleProspectId }) {
      if (showRoleProspectId) {
        const toRemove = this.forShowRoleProspectId(showRoleProspectId).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    // async retrieveAsync(showRoleProspectNoteId, force = false) {
    //   let record = this.records.find((v) => v.id === showRoleProspectNoteId);
    //   if (!record || force) {
    //     await doLoading(this, async () => {
    //       record = await api.showRoleProspectNotes.retrieve({ showRoleProspectId: showRoleProspectNoteId });
    //       if (record) {
    //         record = await this.processRecord(record);
    //       }
    //     });
    //   }
    //   return record;
    // },
    async save(showRoleProspectNote) {
      if (isExisting(showRoleProspectNote.id)) {
        // existing
        const original = this.retrieve(showRoleProspectNote.id);
        const payload = {
          showRoleProspectNoteId: showRoleProspectNote.id,
          updates: buildChanges(original, showRoleProspectNote, { skip: ['id', 'updatedAt', 'enteredById', 'enteredAt'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.showRoleProspectNotes.update(payload).then(async (data) => {
              return this.processRecord(data.showRoleProspectNote);
            });
          } else {
            return original;
          }
        });
      } else {
        // new showRole
        const prepared = {
          showRoleProspectNote: clearEmpty(showRoleProspectNote, { skip: ['enteredById'] }),
        };
        return await doLoading(this, async () => {
          return await api.showRoleProspectNotes.create(prepared).then(async (data) => {
            return this.processRecord(data.showRoleProspectNote);
          });
        });
      }
    },
    async delete(showRoleProspectNoteId) {
      const showRoleProspectNote = this.retrieve(showRoleProspectNoteId);
      if (!showRoleProspectNote) {
        return;
      }
      const payload = { showRoleProspectNoteId };

      return await doLoading(this, async () => {
        return await api.showRoleProspectNotes.delete(payload).then(async data => {
          await this.removeRecord(data.showRoleProspectNoteId);
          return data.showRoleProspectNoteId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShowRoleProspectNoteStore, import.meta.hot));
}
