<script>
const CLICKABLE_WHITE_LIST = ['div', 'span', 'input'];

export default {
  name: 'ONavbarItem',
  props: {
    tag: {
      type: String,
      default: 'a',
    },
    active: Boolean,
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.$el.addEventListener('click', this.handleClickEvent);
      document.addEventListener('keyup', this.keyPress);
    }
  },
  unmounted() {
    if (typeof window !== 'undefined') {
      this.$el.removeEventListener('click', this.handleClickEvent);
      document.removeEventListener('keyup', this.keyPress);
    }
  },
  methods: {
    keyPress({ key }) {
      if (key === 'Escape' || key === 'Esc') {
        this.closeMenuRecursive(this, ['NavBar']);
      }
    },
    /**
     * Close parent if clicked outside.
     */
    handleClickEvent(event) {
      const isOnWhiteList = CLICKABLE_WHITE_LIST.some((item) => item === event.target.localName);
      if (!isOnWhiteList) {
        const parent = this.closeMenuRecursive(this, ['NavbarDropdown', 'NavBar']);
        if (parent && parent.$data.isNavbarDropdown) this.closeMenuRecursive(parent, ['NavBar']);
      }
    },
    /**
     * Close parent recursively
     */
    closeMenuRecursive(current, targetComponents) {
      if (!current.$parent) return null;
      const foundItem = targetComponents.reduce((acc, item) => {
        if (current.$parent.$data[`is${item}`]) {
          current.$parent.closeMenu();
          return current.$parent;
        }
        return acc;
      }, null);
      return foundItem || this.closeMenuRecursive(current.$parent, targetComponents);
    },
  },
};
</script>

<template lang="pug">
component(:is="tag" class="navbar-item")
  slot
</template>

<style scoped>

</style>
