<template lang="pug">
.modal-card
  section.modal-card-body.is-titleless
    .media
      .media-left(v-if="hasIcon")
        OIcon(
          both
          size="large"
          :variant="variant"
          :icon="icon ? icon : iconByVariant"
        )
      .media-content
        // eslint-disable-next-line vue/no-v-html
        p(v-html="message")
  footer.modal-card-foot
    OButton(
      ref="cancelButton"
      @click="$emit('close', {action: 'cancel', method: 'button'})"
    ) {{ cancelText }}
    OButton(
      :variant="variant"
      @click="$emit('close', {action: 'ok', method: 'button'})"
    ) {{ confirmText }}
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    message: { type:String, required: true },
    variant: { type: String, default: 'danger' },
    hasIcon: { type: Boolean, default: true },
    icon: { type: String, default: null },
    confirmText: { type: String, default: 'Confirm' },
    cancelText: { type: String, default: 'Cancel' },
  },
  emits: ['close'],
  computed: {
    iconByVariant() {
      switch(this.variant) {
      case 'info':
        return 'information';
      case 'success':
        return 'check-circle';
      case 'warning':
        return 'alert';
      case 'danger':
        return 'alert-circle';
      default:
        return null;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.cancelButton.$el.focus();
    });
  },
};
</script>

<style scoped>

</style>
