import { graph, paramsWithRole } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils.js';

// region graphql
graph.fragment({
  showBasic: `on Show {
    id
    name
    details @include(if: $isAdmin)
    startDate
    stopDate
    ...showPosterUrls
    position
    seasonId
    status
    createdAt
    updatedAt
    season {
      ...seasonBasic
    }
  }`,
  showPosterUrls: `on Show {
    posterUrls { style url expiration fileName }
  }`,
  showForActor: `on Show {
    id
    name
    startDate
    stopDate
    status
    seasonId
    season {
      ...seasonBasic
    }
    ...showPosterUrls
    createdAt
    updatedAt
  }`,
  showFull: `on Show {
    ...showBasic
    roles {
      ...showRoleFull
    }
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String, $seasonId: SeasonID, $isAdmin: Boolean) {
  shows(first: $limit, after: $after, seasonId: $seasonId) {
    nodes { ...showBasic }
    pageInfo { ...pageInfo }
  }
}`);

const retrieveQuery = graph.query(`($showId: ShowID!, $isAdmin: Boolean) {
  show(showId: $showId) {
    ...showFull
  }
}`);

const retrievePosterUrlsQuery = graph.query(`($showId: ShowID!) {
  show(showId: $showId) {
    id
    ...showPosterUrls
  }
}`);

const createMutation = graph.mutate(`($input: CreateShowInput!, $isAdmin: Boolean) {
  createShow(input: $input) {
    show { ...showBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateShowInput!, $isAdmin: Boolean) {
  updateShow(input: $input) {
    show { ...showBasic }
  }
}`);

const uploadPosterMutation = graph.mutate(`($input: UploadShowPosterInput!) {
  uploadShowPoster(input: $input) {
    show {
     id
     ...showPosterUrls
    }
    presignedPost { ...presignedPost }
  }
}`);

const reorderMutation = graph.mutate(`($input: ReorderShowInput!) {
  reorderShow(input: $input) {
    show { id position }
    updatedShows { id position }
  }
}`);


const deleteMutation = graph.mutate(`($input: DeleteShowInput!) {
  deleteShow(input: $input) { showId }
}`);

// endregion

export function list({ limit = 10, after = null, seasonId = null } = {}) {
  return listQuery(paramsWithRole({ limit, after, seasonId }))
    .catch(wrapGraphQLError);
}

export function retrieve({ showId } = {}) {
  return retrieveQuery(paramsWithRole({ showId }))
    .catch(wrapGraphQLError)
    .then((data) => data.show);
}

export function retrievePosterUrls({ showId } = {}) {
  return retrievePosterUrlsQuery({ showId })
    .catch(wrapGraphQLError)
    .then((data) => data.show);
}

export function create({ show }) {
  return createMutation(paramsWithRole({ input: show }))
    .catch(wrapGraphQLError)
    .then(result => result.createShow);
}

export function update({ showId, updates }) {
  return updateMutation(paramsWithRole({ input: { showId, ...updates } }))
    .catch(wrapGraphQLError)
    .then(result => result.updateShow);
}

export function uploadPoster({ showId, ...options }) {
  return uploadPosterMutation({ input: { showId, ...options } })
    .catch(wrapGraphQLError)
    .then(result => result.uploadShowPoster);
}

export function reorder({ showId, position, relatedShowId = null }) {
  return reorderMutation({ input: { showId, position, relatedShowId } })
    .catch(wrapGraphQLError)
    .then(result => result.reorderShow);
}

function _delete({ showId }) {
  return deleteMutation({ input: { showId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteShow);
}

export { _delete as delete };
