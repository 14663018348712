import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import {
  doLoading,
  addUpdateRecord,
  clearEmpty,
  isExisting,
  removeRecord, buildChanges,
} from '@/utilities/helpers.js';
import { useTagStore } from "@/stores/tags.js";

export const useNoteTopicStore = defineStore('noteTopics', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (noteTopicId) => state.records.find((v) => v.id === noteTopicId),
    isLoading: (state) => state.loading > 0,
  },
  actions: {
    async processRecord(record) {
      await useTagStore().processRecords(record.autoTags);
      return addUpdateRecord(this.records, record, {
        skip: ['autoTags'],
      });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(noteTopicId) {
      removeRecord(this.records, noteTopicId);
    },
    async sync(adminUI = false) {
      await doLoading(this, async () => {
        await api.utils.paginateAll({
          fetch: api.noteTopics.list,
          node: 'noteTopics',
          filters: { limit: 100, adminUI },
        }, async (edge) => {
          await this.processRecord(edge.node);
        });
      });
    },
    async save(noteTopic) {
      if (isExisting(noteTopic.id)) {
        // existing
        const original = this.retrieve(noteTopic.id);
        const payload = {
          noteTopicId: noteTopic.id,
          updates: buildChanges(original, noteTopic, { skip: ['id', 'updatedAt'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.noteTopics.update(payload).then(async (data) => {
              return this.processRecord(data.noteTopic);
            });
          } else {
            return original;
          }
        });
      } else {
        // new noteTopic
        const prepared = {
          noteTopic: clearEmpty(noteTopic),
        };
        return await doLoading(this, async () => {
          return await api.noteTopics.create(prepared).then(async (data) => {
            return this.processRecord(data.noteTopic);
          });
        });
      }
    },
    async delete(noteTopicId) {
      const noteTopic = this.retrieve(noteTopicId);
      if (!noteTopic) {
        return;
      }
      const payload = { noteTopicId };

      return await doLoading(this, async () => {
        return await api.noteTopics.delete(payload).then(async data => {
          await this.removeRecord(data.noteTopicId);
          return data.noteTopicId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNoteTopicStore, import.meta.hot));
}
