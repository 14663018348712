export async function processRelayConnection(src, cb) {
  if ('edges' in src) {
    for (const edge of src.edges) {
      await cb(edge);
    }
  } else if ('nodes' in src) {
    for (const node of src.nodes) {
      await cb({ node: node });
    }
  }
}

export async function paginateAll({
  fetch,
  node,
  filters = {},
  filtersCB = null,
  firstPayloadCB = null,
}, processEdgeCb) {
  let pagedData = {};
  let iteration = 0;
  do {
    const requestFilters = { ...filters };
    if (pagedData.pageInfo?.hasNextPage) {
      requestFilters.after = pagedData.pageInfo.endCursor;
    }
    filtersCB?.call(null, requestFilters, iteration);
    const data = await fetch(requestFilters);
    if (iteration === 0) {
      firstPayloadCB?.call(null, data);
    }
    pagedData = data[node];
    await processRelayConnection(pagedData, async (edge) => {
      await processEdgeCb(edge);
    });
    iteration += 1;
  } while (pagedData.pageInfo?.hasNextPage);
}

const GraphQLErrorSymbol = Symbol(import.meta.env.DEV ? 'GraphQL error' : '');

export function isGraphQLError(error) {
  return (error instanceof Error && GraphQLErrorSymbol in error);
}

export function createGraphQLError(errors) {
  return Object.assign(
    new Error(errors[0]?.message || errors.message || 'Unknown GraphQL Error'),
    {
      errors,
      [GraphQLErrorSymbol]: true,
    },
  );
}

export function wrapGraphQLError(errors) {
  throw createGraphQLError(errors);
}
