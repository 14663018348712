<script>

import ONavbarBurger from '@/components/NavbarBurger.vue';

const FIXED_TOP_CLASS = 'is-fixed-top';
const BODY_FIXED_TOP_CLASS = 'has-navbar-fixed-top';
const BODY_SPACED_FIXED_TOP_CLASS = 'has-spaced-navbar-fixed-top';
const FIXED_BOTTOM_CLASS = 'is-fixed-bottom';
const BODY_FIXED_BOTTOM_CLASS = 'has-navbar-fixed-bottom';
const BODY_SPACED_FIXED_BOTTOM_CLASS = 'has-spaced-navbar-fixed-bottom';
const BODY_CENTERED_CLASS = 'has-navbar-centered';

export default {
  name: 'ONavbar',
  components: { ONavbarBurger },
  props: {
    variant: {
      type:String,
      default: null,
    },
    fixedTop: {
      type: Boolean,
      default: false,
    },
    fixedBottom: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    wrapperClass: { // TODO implement
      type: [String, Array, Object],
      default: null,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    mobileBurger: {
      type: Boolean,
      default: true,
    },
    spaced: Boolean,
    shadow: Boolean,
  },
  emits: ['update:active'],
  data() {
    return {
      internalIsActive: this.active,
      isNavBar: true, // Used internally by NavbarItem
    };
  },
  computed: {
    isOpened() {
      return this.internalIsActive;
    },
    computedClasses() {
      return [
        {
          [FIXED_TOP_CLASS]: this.fixedTop,
          [FIXED_BOTTOM_CLASS]: this.fixedBottom,
          [BODY_CENTERED_CLASS]: this.centered,
          'is-spaced': this.spaced,
          'has-shadow': this.shadow,
          'is-transparent': this.transparent,
          [`is-${this.variant}`]: this.variant,
        },
      ];
    },
  },
  watch: {
    active: {
      handler(active) {
        this.internalIsActive = active;
      },
      immediate: true,
    },
    fixedTop(isSet) {
      // toggle body class only on update to handle multiple navbar
      this.setBodyFixedTopClass(isSet);
    },
    bottomTop(isSet) {
      // toggle body class only on update to handle multiple navbar
      this.setBodyFixedBottomClass(isSet);
    },
  },
  beforeMount() {
    this.fixedTop && this.setBodyFixedTopClass(true);
    this.fixedBottom && this.setBodyFixedBottomClass(true);
  },
  mounted() {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside);
    }
  },
  unmounted() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside);
    }
    if (this.fixedTop) {
      const className = this.spaced
        ? BODY_SPACED_FIXED_TOP_CLASS : BODY_FIXED_TOP_CLASS;
      this.removeBodyClass(className);
    } else if (this.fixedBottom) {
      const className = this.spaced
        ? BODY_SPACED_FIXED_BOTTOM_CLASS : BODY_FIXED_BOTTOM_CLASS;
      this.removeBodyClass(className);
    }
  },
  methods: {
    toggleActive() {
      this.internalIsActive = !this.internalIsActive;
      this.emitUpdateParentEvent();
    },
    closeMenu() {
      if (this.closeOnClick && this.internalIsActive) {
        this.internalIsActive = false;
        this.emitUpdateParentEvent();
      }
    },
    emitUpdateParentEvent() {
      this.$emit('update:active', this.internalIsActive);
    },
    /**
     * White-listed items to not close when clicked.
     */
    isInWhiteList(el) {
      if (el === this.$el) return true;
      // All children of the navbar
      const children = this.$el.querySelectorAll('*');
      for (const child of children) {
        if (el === child) {
          return true;
        }
      }
      return false;
    },
    clickedOutside(event) {
      if (!this.isInWhiteList(event.target)) this.closeMenu();
    },
    setBodyClass(className) {
      if (typeof window !== 'undefined') {
        document.body.classList.add(className);
      }
    },
    removeBodyClass(className) {
      if (typeof window !== 'undefined') {
        document.body.classList.remove(className);
      }
    },
    checkIfFixedPropertiesAreColliding() {
      const areColliding = this.fixedTop && this.fixedBottom;
      if (areColliding) {
        throw new Error('You should choose if the ONavbar is fixed bottom or fixed top, but not both');
      }
    },
    setBodyFixedTopClass(isSet) {
      this.checkIfFixedPropertiesAreColliding();
      if (isSet) {
        // TODO Apply only one of the classes once PR is merged in Bulma:
        // https://github.com/jgthms/bulma/pull/2737
        this.setBodyClass(BODY_FIXED_TOP_CLASS);
        this.spaced && this.setBodyClass(BODY_SPACED_FIXED_TOP_CLASS);
      } else {
        this.removeBodyClass(BODY_FIXED_TOP_CLASS);
        this.removeBodyClass(BODY_SPACED_FIXED_TOP_CLASS);
      }
    },
    setBodyFixedBottomClass(isSet) {
      this.checkIfFixedPropertiesAreColliding();
      if (isSet) {
        // TODO Apply only one of the classes once PR is merged in Bulma:
        // https://github.com/jgthms/bulma/pull/2737
        this.setBodyClass(BODY_FIXED_BOTTOM_CLASS);
        this.spaced && this.setBodyClass(BODY_SPACED_FIXED_BOTTOM_CLASS);
      } else {
        this.removeBodyClass(BODY_FIXED_BOTTOM_CLASS);
        this.removeBodyClass(BODY_SPACED_FIXED_BOTTOM_CLASS);
      }
    },
  },
};
</script>

<template lang="pug">
nav.navbar(
  aria-label="main navigation"
  role="navigation"
  :class="computedClasses"
)
  .navbar-brand
    slot(name="brand")
    template(v-if="mobileBurger")
      slot(
        :toggle-active="toggleActive"
        :is-opened="isOpened"
        name="burger"
      )
        ONavbarBurger(
          :is-opened="isOpened"
          @keyup.enter="toggleActive"
          @click="toggleActive"
        )
  .navbar-menu(:class="{'is-active': isOpened}")
    .navbar-start
      slot(name="start")
    .navbar-end
      slot(name="end")
</template>

<style scoped>

</style>
