import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers.js';
import { UploadManager } from '@/utilities/upload_manager';
import { urlsExpired } from '@/utilities/helpers.js';
import { useActorStore } from '@/stores/actors.js';

export const useActorHeadshotStore = defineStore('actorHeadshots', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (actorHeadshotId) => state.records.find((v) => v.id === actorHeadshotId),
    isLoading: (state) => state.loading > 0,
    forActorId: (state) => (actorId) => state.records.filter((v) => v.actorId === actorId),
  },
  actions: {
    async processRecord(record) {
      return addUpdateRecord(this.records, record);
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(actorHeadshotId) {
      removeRecord(this.records, actorHeadshotId);
    },
    async removeRecordsBy({ actorId }) {
      if (actorId) {
        const toRemove = this.forActorId(actorId).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    async retrieveAsync(actorHeadshotId, force = false) {
      let record = this.records.find((v) => v.id === actorHeadshotId);
      if (!record || force) {
        await doLoading(this, async () => {
          record = await api.actorHeadshots.retrieve({ actorHeadshotId });
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async sync() {
      await doLoading(this, async () => {
        await api.utils.paginateAll({
          fetch: api.actorHeadshots.list,
          node: 'actorHeadshots',
          filters: { limit: 100 },
        }, async (edge) => {
          await this.processRecord(edge.node);
        });
      });
    },
    async retrieveUrls(actorHeadshotId) {
      let record = this.records.find((v) => v.id === actorHeadshotId);
      await doLoading(this, async () => {
        if (!record) {
          record = await api.actorHeadshots.retrieve({ actorHeadshotId });
          record = await this.processRecord(record);
        } else {
          if (urlsExpired(record.urls)) {
            record = await api.actorHeadshots.retrieveUrls({ actorHeadshotId });
            record = await this.processRecord(record);
          }
        }
      });
      return record;
    },
    async startUpload({ actorHeadshotId, contentType, fileName }) {
      const initial = await api.actorHeadshots.upload({
        actorHeadshotId, upload: { start: { contentType, fileName } },
      });
      await this.processRecord(initial.actorHeadshot);
      return new UploadManager(initial.presignedPost, async (completionToken) => {
        const data = await api.actorHeadshots.upload({ actorHeadshotId, upload: { finish: { completionToken } } });
        await this.processRecord(data.actorHeadshot);
      });
    },
    async save(actorHeadshot) {
      if (isExisting(actorHeadshot.id)) {
        // existing
        const original = this.retrieve(actorHeadshot.id);
        const payload = {
          actorHeadshotId: actorHeadshot.id,
          updates: buildChanges(original, actorHeadshot, { skip: ['id', 'updatedAt', 'urls'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.actorHeadshots.update(payload).then(async (data) => {
              return this.processRecord(data.actorHeadshot);
            });
          } else {
            return original;
          }
        });
      } else {
        // new actorHeadshot
        const prepared = {
          actorHeadshot: clearEmpty(actorHeadshot, { skip: ['urls'] }),
        };
        return await doLoading(this, async () => {
          return await api.actorHeadshots.create(prepared).then(async (data) => {
            await useActorStore().processRecord({
              id: data.actorHeadshot.actorId,
              primaryActorHeadshotId: data.primaryActorHeadshotId,
            });
            return this.processRecord(data.actorHeadshot);
          });
        });
      }
    },
    async delete(actorHeadshotId) {
      const actorHeadshot = this.retrieve(actorHeadshotId);
      if (!actorHeadshot) {
        return;
      }
      const payload = { actorHeadshotId };

      return await doLoading(this, async () => {
        return await api.actorHeadshots.delete(payload).then(async data => {
          await this.removeRecord(data.actorHeadshotId);
          await useActorStore().processRecord({
            id: actorHeadshot.actorId,
            primaryActorHeadshotId: data.primaryActorHeadshotId,
          });
          return data.actorHeadshotId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActorHeadshotStore, import.meta.hot));
}
