import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  showRoleProspectNoteBasic: `on ShowRoleProspectNote {
    id
    showRoleProspectId
    ...noteBasic
  }`,
  showRoleProspectNoteFull: `on ShowRoleProspectNote {
    id
    showRoleProspectId
    ...noteFull
  }`,
});

const createMutation = graph.mutate(`($input: CreateShowRoleProspectNoteInput!) {
  createShowRoleProspectNote(input: $input) {
    showRoleProspectNote { ...showRoleProspectNoteBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateShowRoleProspectNoteInput!) {
  updateShowRoleProspectNote(input: $input) {
    showRoleProspectNote { ...showRoleProspectNoteBasic }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteShowRoleProspectNoteInput!) {
  deleteShowRoleProspectNote(input: $input) { showRoleProspectNoteId }
}`);

// endregion

export function create({ showRoleProspectNote }) {
  return createMutation({ input: showRoleProspectNote })
    .catch(wrapGraphQLError)
    .then(result => result.createShowRoleProspectNote);
}

export function update({ showRoleProspectNoteId, updates }) {
  return updateMutation({ input: { showRoleProspectNoteId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateShowRoleProspectNote);
}

function _delete({ showRoleProspectNoteId }) {
  return deleteMutation({ input: { showRoleProspectNoteId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteShowRoleProspectNote);
}

export { _delete as delete };
