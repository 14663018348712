import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  userBasic: `on User {
    id
    name
    createdAt
    updatedAt
  }`,
  userFull: `on User {
    ...userBasic
    email
    role
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String) {
  users(first: $limit, after: $after) {
    nodes { ...userFull }
    pageInfo { ...pageInfo }
  }
}`);

const retrieveQuery = graph.query(`($userId: UserID!) {
  user(userId: $userId) {
    ...userBasic
  }
}`);

const createMutation = graph.mutate(`($input: CreateUserInput!) {
  createUser(input: $input) {
    user { ...userFull }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user { ...userFull }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteUserInput!) {
  deleteUser(input: $input) { userId }
}`);

// endregion

export function list({ limit = 10, after = null } = {}) {
  return listQuery({ limit, after })
    .catch(wrapGraphQLError);
}

export function retrieve({ userId } = {}) {
  return retrieveQuery({ userId })
    .catch(wrapGraphQLError)
    .then((data) => data.user);
}

export function create({ user }) {
  return createMutation({ input: user })
    .catch(wrapGraphQLError)
    .then(result => result.createUser);
}

export function update({ userId, updates }) {
  return updateMutation({ input: { userId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateUser);
}

function _delete({ userId }) {
  return deleteMutation({ input: { userId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteUser);
}

export { _delete as delete };
