import * as Sentry from '@sentry/vue';

function enabled() {
  return import.meta.env.MODE === 'production';
}

export function SetupErrorHandling(app, _options = {}) {
  if (!enabled()) return;

  Sentry.init({
    app,
    dsn: 'https://c400703bbdf353024e7c321c885c56ea@o4506935111843840.ingest.us.sentry.io/4506935113678848',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin + '/graphql'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function SetErrorHandlerUser(args) {
  if (!enabled()) return;

  Sentry.setUser(args);
}

export function ReportError(error) {
  Sentry.captureException(error);
}
