import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  seasonBasic: `on Season {
    id
    name
    startDate
    stopDate
    ...seasonPosterUrls
    position
    createdAt
    updatedAt
  }`,
  seasonPosterUrls: `on Season {
    posterUrls { style url expiration fileName }
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String) {
  seasons(first: $limit, after: $after) {
    nodes { ...seasonBasic }
    pageInfo { ...pageInfo }
  }
}`);

const retrieveQuery = graph.query(`($seasonId: SeasonID!) {
  season(seasonId: $seasonId) {
    ...seasonBasic
  }
}`);

const retrievePosterUrlsQuery = graph.query(`($seasonId: SeasonID!) {
  season(seasonId: $seasonId) {
    id
    ...seasonPosterUrls
  }
}`);

const createMutation = graph.mutate(`($input: CreateSeasonInput!) {
  createSeason(input: $input) {
    season { ...seasonBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateSeasonInput!) {
  updateSeason(input: $input) {
    season { ...seasonBasic }
  }
}`);

const uploadPosterMutation = graph.mutate(`($input: UploadSeasonPosterInput!) {
  uploadSeasonPoster(input: $input) {
    season {
     id
     ...seasonPosterUrls
    }
    presignedPost { ...presignedPost }
  }
}`);

const reorderMutation = graph.mutate(`($input: ReorderSeasonInput!) {
  reorderSeason(input: $input) {
    season { id position }
    updatedSeasons { id position }
  }
}`);


const deleteMutation = graph.mutate(`($input: DeleteSeasonInput!) {
  deleteSeason(input: $input) { seasonId }
}`);

// endregion

export function list({ limit = 10, after = null } = {}) {
  return listQuery({ limit, after })
    .catch(wrapGraphQLError);
}

export function retrieve({ seasonId } = {}) {
  return retrieveQuery({ seasonId })
    .catch(wrapGraphQLError)
    .then((data) => data.season);
}

export function retrievePosterUrls({ seasonId } = {}) {
  return retrievePosterUrlsQuery({ seasonId })
    .catch(wrapGraphQLError)
    .then((data) => data.season);
}

export function create({ season }) {
  return createMutation({ input: season })
    .catch(wrapGraphQLError)
    .then(result => result.createSeason);
}

export function update({ seasonId, updates }) {
  return updateMutation({ input: { seasonId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateSeason);
}

export function uploadPoster({ seasonId, ...options }) {
  return uploadPosterMutation({ input: { seasonId, ...options } })
    .catch(wrapGraphQLError)
    .then(result => result.uploadSeasonPoster);
}

export function reorder({ seasonId, position, relatedSeasonId = null }) {
  return reorderMutation({ input: { seasonId, position, relatedSeasonId } })
    .catch(wrapGraphQLError)
    .then(result => result.reorderSeason);
}

function _delete({ seasonId }) {
  return deleteMutation({ input: { seasonId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteSeason);
}

export { _delete as delete };
