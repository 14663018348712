import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  actorVideoBasic: `on ActorVideo {
    id
    actorId
    name
    videoLink { url }
    position
    createdAt
    updatedAt
  }`,
});

const listQuery = graph.query(`($actorId: ActorID!, $limit: Int, $after: String) {
  actorVideos(first: $limit, after: $after, actorId: $actorId) {
    nodes { ...actorVideoBasic }
    pageInfo { ...pageInfo }
  }
}`);

const retrieveQuery = graph.query(`($actorVideoId: ActorVideoID!) {
  actorVideo(actorVideoId: $actorVideoId) {
    ...actorVideoBasic
  }
}`);

const createMutation = graph.mutate(`($input: CreateActorVideoInput!) {
  createActorVideo(input: $input) {
    actorVideo { ...actorVideoBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateActorVideoInput!) {
  updateActorVideo(input: $input) {
    actorVideo { ...actorVideoBasic }
  }
}`);

const reorderMutation = graph.mutate(`($input: ReorderActorVideoInput!) {
  reorderActorVideo(input: $input) {
    actorVideo { id position }
    updatedActorVideos { id position }
  }
}`);


const deleteMutation = graph.mutate(`($input: DeleteActorVideoInput!) {
  deleteActorVideo(input: $input) { actorVideoId }
}`);

// endregion

export function list({ actorId, limit = 10, after = null } = {}) {
  return listQuery({ limit, after, actorId })
    .catch(wrapGraphQLError);
}

export function retrieve({ actorVideoId } = {}) {
  return retrieveQuery({ actorVideoId })
    .catch(wrapGraphQLError)
    .then((data) => data.actorVideo);
}

export function create({ actorVideo }) {
  return createMutation({ input: actorVideo })
    .catch(wrapGraphQLError)
    .then(result => result.createActorVideo);
}

export function update({ actorVideoId, updates }) {
  return updateMutation({ input: { actorVideoId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateActorVideo);
}

export function reorder({ actorVideoId, position, relatedActorVideoId = null }) {
  return reorderMutation({ input: { actorVideoId, position, relatedActorVideoId } })
    .catch(wrapGraphQLError)
    .then(result => result.reorderActorVideo);
}

function _delete({ actorVideoId }) {
  return deleteMutation({ input: { actorVideoId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteActorVideo);
}

export { _delete as delete };
