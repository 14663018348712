import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  skillCategoryBasic: `on SkillCategory {
    id
    category
  }`,
  skillBasic: `on Skill {
    id
    skill
    options {
      colorName
    }
    skillCategoryId
    category
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String, $category: String, $categoryId: SkillCategoryID) {
  skills(first: $limit, after: $after, skillCategoryId: $categoryId, category: $category) {
    nodes { ...skillBasic }
    pageInfo { ...pageInfo }
  }
}`);

const listCategoriesQuery = graph.query(`($limit: Int, $after: String) {
  skillCategories(first: $limit, after: $after) {
    nodes { ...skillCategoryBasic }
    pageInfo { ...pageInfo }
  }
}`);

const createMutation = graph.mutate(`($input: CreateSkillInput!) {
  createSkill(input: $input) {
    skill { ...skillBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateSkillInput!) {
  updateSkill(input: $input) {
    skill { ...skillBasic }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteSkillInput!) {
  deleteSkill(input: $input) { skillId }
}`);

// endregion

export function list({ limit = 10, after = null, category = null, categoryId = null } = {}) {
  return listQuery({ limit, after, category, categoryId })
    .catch(wrapGraphQLError);
}

export function listCategories({ limit = 10, after = null } = {}) {
  return listCategoriesQuery({ limit, after })
    .catch(wrapGraphQLError);
}

export function create({ skill }) {
  return createMutation({ input: skill })
    .catch(wrapGraphQLError)
    .then(result => result.createSkill);
}

export function update({ skillId, updates }) {
  return updateMutation({ input: { skillId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateSkill);
}

function _delete({ skillId }) {
  return deleteMutation({ input: { skillId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteSkill);
}

export { _delete as delete };
