import { defineStore, acceptHMRUpdate } from 'pinia';
import { useActorStore } from '@/stores/actors';
import * as api from '@/api';
import {
  doLoading,
  addUpdateRecord,
  clearEmpty,
  isExisting,
  removeRecord, buildChanges,
} from '@/utilities/helpers.js';

export const useTagStore = defineStore('tags', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string, tag: string, tagCategoryId: string}[]} */
    records: [],
    /** @type {{id: string, category: string}[]} */
    categories: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (tagId) => state.records.find((v) => v.id === tagId),
    isLoading: (state) => state.loading > 0,
  },
  actions: {
    forCategory(category) {
      const categoryId = this.categories.find(v => v.category === category)?.id;
      return this.forCategoryId(categoryId);
    },
    forCategoryId(categoryId) {
      return this.records.filter((v) => v.tagCategoryId === categoryId);
    },
    forActorId(actorId) {
      const actor = useActorStore().retrieve(actorId);
      return actor.tagIds.map((tagId) => this.retrieve(tagId) || {});
    },
    async processRecord(record) {
      this.processCategoryRecord({
        id: record.tagCategoryId,
        category: record.category,
      });
      return addUpdateRecord(this.records, record);
    },
    async processCategoryRecord(record) {
      return addUpdateRecord(this.categories, record);
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(tagId) {
      removeRecord(this.records, tagId);
    },
    async removeRecordsBy({ category }) {
      if (category) {
        const toRemove = this.forCategory(category).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    async syncCategories() {
      await doLoading(this, async () => {
        await api.utils.paginateAll({
          fetch: api.tags.listCategories,
          node: 'tagCategories',
          filters: { limit: 100 },
        }, async (edge) => {
          await this.processCategoryRecord(edge.node);
        });
      });
    },
    async sync() {
      await doLoading(this, async () => {
        await api.utils.paginateAll({ fetch: api.tags.list, node: 'tags', filters: { limit: 100 } }, async (edge) => {
          await this.processRecord(edge.node);
        });
      });
    },
    async save(tag) {
      if (isExisting(tag.id)) {
        // existing
        const original = this.retrieve(tag.id);
        const payload = {
          tagId: tag.id,
          updates: buildChanges(original, tag, { skip: ['id', 'updatedAt'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.tags.update(payload).then(async (data) => {
              return this.processRecord(data.tag);
            });
          } else {
            return original;
          }
        });
      } else {
        // new tag
        const prepared = {
          tag: clearEmpty(tag),
        };
        return await doLoading(this, async () => {
          return await api.tags.create(prepared).then(async (data) => {
            return this.processRecord(data.tag);
          });
        });
      }
    },
    async delete(tagId) {
      const tag = this.retrieve(tagId);
      if (!tag) {
        return;
      }
      const payload = { tagId };

      return await doLoading(this, async () => {
        return await api.tags.delete(payload).then(async data => {
          await this.removeRecord(data.tagId);
          return data.tagId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTagStore, import.meta.hot));
}
